import React from "react"
import { withPrefix } from "gatsby";
import { Link } from "gatsby"

import Helmet from 'react-helmet';
import Check from '../images/check.png';
import BG from "../images/bg_full.jpg"

export default function Home() {
  return (
    <div style={{
      backgroundImage: 'url('+BG+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'bottom'
    }}>
    <Helmet>
      <title>Farma u Matěje | Objednávka přijata</title>
      <script src={withPrefix('../../scripts/jquery.min.js')} type="text/javascript" />
      <script src={withPrefix('../../scripts/jquery.scrollex.min.js')} type="text/javascript" />
      <script src={withPrefix('../../scripts/jquery.scrolly.min.js')} type="text/javascript" />
      <script src={withPrefix('../../scripts/browser.min.js')} type="text/javascript" />
      <script src={withPrefix('../../scripts/breakpoints.min.js')} type="text/javascript" />
      <script src={withPrefix('../../scripts/util.js')} type="text/javascript" />
      <script src={withPrefix('../../scripts/main.js')} type="text/javascript" />

      <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('../../apple-touch-icon.png')}/>
      <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('../../favicon-32x32.png')}/>
      <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('../../favicon-16x16.png')}/>
      <link rel="manifest" href={withPrefix('../../site.webmanifest')}/>
    </Helmet>
    <div id="wrapper">

      <header id="header" className="alt">
        <span className="logo"><img src="images/logo.svg" alt="" /></span>
        <h1>FARMA U MATĚJE</h1>
        <p>Vaše rodinná farma z Východních Čech</p>
      </header>

      <nav id="nav">
        <ul>
          <li><Link to="/">Úvod</Link></li>
          <li><Link to="/#o-nas">O nás</Link></li>
          <li><Link to="/#vize">Vize</Link></li>
          <li><Link to="/#kruty">Krůty</Link></li>
          <li><Link to="/#kurata">Kuřata</Link></li>
          <li><Link to="/#kontakt">Kontakt</Link></li>
          <li><Link to="/#objednavky">Objednávky</Link></li>
        </ul>
      </nav>

      <div id="main">

        <section id="intro" className="main">
          <div className="spotlight">
            <div className="content">
              <header className="major">
                <h2>Objednávka přijata</h2>
              </header>
              <p>Vaší objednávku jsme úspěšně přijali a nyní ji zpracováváme. Brzy vás kontaktujeme.</p>
            </div>
            <span className="image"><img src={Check} alt="" /></span>
          </div>
        </section>

    </div>
    </div>
      <footer id="footer">
      </footer>
    </div>
  )
}
